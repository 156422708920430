<template>
  <v-container fluid class="pa-0">
    <!--    <div class="imagen"></div>-->
    <!--    <div class="img-cont">-->
    <!--      <img src="../../../../assets/images/inicio.png" alt="" class="img-inicio" />-->
    <!--    </div>-->
    <BaseCard heading="INICIO">
      <div>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <!--              <h1>Inicio</h1>-->
              <!--              <v-btn @click="bulk">bulk</v-btn>-->
              <!--              <v-btn @click="getbulk">getbulk</v-btn>-->
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </BaseCard>
  </v-container>
</template>

<script>
import fire from '@/fire';

export default {
  name: 'Inicio',

  data: () => ({}),
  methods: {
    async bulk() {
      const data = [
        {
          id: 'RMBME8DAJH',
          body: {
            KKQB9HZAIR: {
              name: 'MESA ARÁNDANO 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            PV0173LJMG: {
              name: 'MESA ARÁNDANO 002',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            QVIPTOACYP: {
              name: 'MESA ARÁNDANO 003',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            GLQLQ6OMG5: {
              name: 'MESA ARÁNDANO 004',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            TLPQ0KKKQ7: {
              name: 'MESA ARÁNDANO 005',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            G2CZRFHBDV: {
              name: 'MESA UVA 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            '18TZR3WMNE': {
              name: 'MESA UVA 002',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            '1279ZUUVMF': {
              name: 'MESA ESPÁRRAGO 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            AG7D745417: {
              name: 'MESA PALTO 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            GMSPKS1V7V: {
              name: 'MESA PACKING 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            '5JPN9FIXXM': {
              name: 'MESA A9 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            U5QAIX1QL0: {
              name: 'MESA A9 002',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            I84DK0BBPV: {
              name: 'MESA A9 003',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            },
            G7KZ86TY12: {
              name: 'MESA VIRTUAL 001',
              candidatos: {
                LBU102SJ1I: {
                  dni: '76619946',
                  name: 'NADIA DIAZ MORALES',
                  count: 0
                },
                '2PZYHP2TCB': {
                  dni: '17607036',
                  name: 'FELIMON TIQUILLAHUANCA TIQUILLAHUANCA',
                  count: 0
                },
                GPK8570ZMI: {
                  dni: '74850712',
                  name: 'ALICIA SEDAMANO CASTILLO',
                  count: 0
                },
                AASXL8H7ZK: {
                  dni: '46812014',
                  name: 'LUIS BARTOLO RODRIGUEZ',
                  count: 0
                },
                UHU7YPNO5O: {
                  dni: '75194077',
                  name: 'VANESSA ROQUE GALVEZ',
                  count: 0
                },
                '9OPHJZQJYH': {
                  dni: '75597167',
                  name: 'ALDAIR VASQUEZ PITA',
                  count: 0
                },
                WMY4SZ64YL: {
                  dni: '71560932',
                  name: 'EDWIN TINEO PEREZ',
                  count: 0
                },
                MWZULOSWSP: {
                  dni: '73445497',
                  name: 'ESLIN JULCA TIQUILLAHUANCA',
                  count: 0
                },
                Q6LGUYB0LM: {
                  dni: '44707283',
                  name: 'DARIO TIQUILLAHUANCA BARRIO',
                  count: 0
                },
                IOCZM3RCZY: {
                  dni: '45196425',
                  name: 'AURELIO MANAYAY QUISPE',
                  count: 0
                },
                '5461G226GJ': {
                  dni: '48546662',
                  name: 'DARWIN SERRATO PUPUCHE',
                  count: 0
                },
                DJANDOUMCZ: {
                  dni: '45214799',
                  name: 'HEYLER BURGA DIAZ',
                  count: 0
                },
                ANVDY2G55V: {
                  dni: '74429354',
                  name: 'CLAUDIA QUIROZ MONCADA',
                  count: 0
                },
                JLCHYV1CP5: {
                  dni: '17445276',
                  name: 'JAVIER APONTE SUCLUPE',
                  count: 0
                },
                QI9UA4WDXD: {
                  dni: '74374577',
                  name: 'MARIA CACERES CASTAÑEDA',
                  count: 0
                },
                XEANVOELEU: {
                  dni: '45230280',
                  name: 'LUIS ZEGARRA CASTRO',
                  count: 0
                },
                WU2Y0FCO4F: {
                  dni: '73700339',
                  name: 'ELIZABETH SONAPO RODRIGUEZ',
                  count: 0
                },
                IEJ4OOZZ0F: {
                  dni: '41613811',
                  name: 'RONALD SIESQUEN ESPINOZA',
                  count: 0
                },
                '496E7WRDZN': {
                  dni: '72527885',
                  name: 'ABRAHAM RORIGUEZ CORREA',
                  count: 0
                },
                TAZHN0ON4H: {
                  dni: '72368657',
                  name: 'JEAN FALCON DIAZ',
                  count: 0
                },
                NSA60ALHWW: {
                  dni: '00000000',
                  name: 'VOTO EN BLANCO',
                  count: 0
                }
              }
            }
          }
        }
      ];
      const itemsRef = fire.database().ref('distribucion_candidatos');

      for (const eleccion of data) {
        const res = await itemsRef.child(eleccion.id).set(eleccion);
        console.log(res);
      }
    }
  }
};
</script>
<style lang="scss">
.imagen {
  width: 100%;
  height: calc(100vh - 61px - 64px - 24px);
  background: url('../../../../assets/images/inicio.png') no-repeat;
  background-size: cover;
}

.img-cont {
  overflow: hidden;
}

.img-inicio {
  width: 100%;
  height: calc(100vh - 61px - 64px - 24px);
}
</style>
